import React, { useEffect } from 'react'
import Wrapper from '../components/wrapper'

const ResponsiveGraphic = ({ location }) => {
  useEffect(() => {
    setTimeout(() => {
      const script = document.createElement('script')
      script.id = 'infogram_0_4bbee25f-036b-4785-90b0-a18f9eacc1bb'
      script.src = 'https://e.infogram.com/js/dist/embed.js?WDL'
      document.getElementById('infogram_wrapper')?.appendChild(script)
    }, 200)
  }, [])

  return (
    <Wrapper location={location} title="Responsive Graphic | MedReps.com">
      <div
        style={{
          padding: '8px 0',
          fontFamily: 'Arial !important',
          fontSize: '13px !important',
          lineHeight: '15px !important',
          textAlign: 'center',
          borderTop: '1px solid #dadada',
          margin: '0 30px',
        }}
      >
        <div id="infogram_wrapper"></div>
        <a
          href="https://infogram.com/4bbee25f-036b-4785-90b0-a18f9eacc1bb"
          style={{
            color: '#989898 !important',
            textDecoration: 'none !important',
          }}
          target="_blank"
        >
          Report Classic
        </a>
        <br />
        <a
          href="https://infogram.com"
          style={{
            color: '#989898 !important',
            textDecoration: 'none !important',
          }}
          target="_blank"
          rel="nofollow"
        >
          Infogram
        </a>
      </div>
    </Wrapper>
  )
}
export default ResponsiveGraphic
